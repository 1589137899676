const countries = [
  {
    id: 'BE',
    text: 'Belgique',
  },
  {
    id: 'FR',
    text: 'France',
  },
  {
    id: 'LU',
    text: 'Luxembourg',
  },
];

export default countries;
