<template>
  <div>
    <router-link :to="to" :class="[buttonClasses, buttonClass]">
      <FontAwesomeIcon v-if="icon" :icon="icon" :class="[$style.icon, iconClass]" />
      <slot />
      <template>{{ text }}</template>
    </router-link>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'Button',
  components: {
    FontAwesomeIcon,
  },
  props: {
    to: String,
    text: String,
    alternate: Boolean,
    small: Boolean,
    extraSmall: Boolean,
    extraSmall2: Boolean,
    linkLike: Boolean,
    linkLikeUnderlined: Boolean,
    buttonClass: String,
    error: Boolean,
    warn: Boolean,
    icon: {
      type: Object,
      required: false,
    },
    iconClass: {
      type: [String, Array],
      required: false,
    },
  },
  computed: {
    buttonClasses() {
      return {
        'lowco-button': true,
        'lowco-button--alternate': this.alternate,
        'lowco-button--small': this.small,
        'lowco-button--extra-small': this.extraSmall,
        'lowco-button--link': this.linkLike,
        'lowco-button--link-underline': this.linkLikeUnderlined,
        'lowco-button--error': this.error,
        'lowco-button--warn': this.warn,
      };
    },
  },
};
</script>

<style lang="scss" module>
.placeholderImage {
  height: 1.6rem;
  width: auto;
}

.icon {
  font-size: 2rem;
  margin-right: 1rem;
}
</style>
