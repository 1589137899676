const GENERAL = 'GENERAL';
const EMPTY_FIELD = 'EMPTY_FIELD';
const VAT_UNVALID = 'VAT_UNVALID';
const DuplicateUserName = 'DuplicateUserName';
const COMPANY_ALREADY_EXIST = 'COMPANY_ALREADY_EXIST';
const SENT = 'SENT';

export default {
  GENERAL,
  EMPTY_FIELD,
  VAT_UNVALID,
  DuplicateUserName,
  SENT,
  COMPANY_ALREADY_EXIST,
};
