import axios from 'axios';

// URL example for address :
// https://nominatim.openstreetmap.org/reverse?format=json&lat=52.5487429714954&lon=-1.81602098644987
const getAddress = async (lat, lon) => axios
  .get(`${process.env.VUE_APP_NOMINATIM_URL}/reverse?format=json&lat=${lat}&lon=${lon}`);

// URL exemple for coordinates :
// https://nominatim.openstreetmap.org/search?q=Route+de+Saint-G%C3%A9rard&
const getCoordinates = async (address, country = 'BE') => axios
  .get(`${process.env.VUE_APP_NOMINATIM_URL}/search?format=json&q=${address}&countrycodes=${country}&accept-language=fr-FR`);

export default {
  getAddress,
  getCoordinates,
};
