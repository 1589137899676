const isVATNumberValid = (vatNumber, country) => {
  if (country !== 'BE') {
    return true;
  }

  // == est ici volontaire, je veux valider si c'est null ou undefined
  if (vatNumber == null) {
    return false;
  }

  const cleanVat = vatNumber.replace(/[\s.]/g, ''); // Remove any whitespace character and separators

  const vatNumberWithoutCS = cleanVat
    .substr(2, cleanVat.length - 4)
    .replace(/\./g, '');

  const checkSum = cleanVat
    .replace(/\./g, '')
    .substr(cleanVat.length - 2, cleanVat.length);

  return (97 - (parseInt(vatNumberWithoutCS, 10) % 97)) === parseInt(checkSum, 10);
};

export default {
  isVATNumberValid,
};
